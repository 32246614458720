* {
  margin: 0;
  padding: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Lexend Deca";
  src: url("./LexendDeca-Regular.ttf");
}

@font-face {
  font-family: "Lexend Mega";
  src: url("./LexendMega-Regular.ttf");
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input:focus-visible {
  outline: none;
}

html {
  height: 100%;
  --main-color: #009a49;
}

a {
  color: #009a49;
  text-decoration: none;
}

body,
html {
  width: 100%;
  color: #000;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

body.overlay:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.02);
}

body.overflow {
  overflow: hidden;
}

#root {
  height: 100%;
}
