.main__text {
	font-family: Lexend Deca, sans-serif;
	font-size: 90px;
	color: #eeeeea;
	text-align: left;
}

.section__number {
	text-align: center;
	color: white;
	font-family: Lexend Deca, sans-serif;
	font-size: 40px;
	width: 900px;
	padding-bottom: 50px;
	word-wrap: break-word;
}

.password__section {
	padding-top: 55px;
	text-align: center;
	font-family: Lexend Deca, sans-serif;
	font-size: 40px;
	cursor: pointer;
	padding-bottom: 50px;
	padding-left: 5px;
}

.pincode {
	margin: 12px;
	padding: 10px;
	border: 1px solid #ddd;
	border-radius: 10px;
	outline: none;
	color: black;
	width: 50px;
	height: 50px;
	text-align: center;
	font-size: 30px;
}
