.page_container {
	height: 100%;
	width: 100%;
	background-color: #eeeeea;
}

.page_wrapper {
  height: 100%;
	position: relative;
	overflow: auto;
}

.bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
  width: 100%;
  /* height: 80%; */
}

.decline-modal-overlay {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 154, 73, 0.2);
	-webkit-backdrop-filter: blur(20px);
	backdrop-filter: blur(20px);
	z-index: 1;
}

.decline-modal-container {
	display: flex;
	flex-direction: column;
	padding: 30px 20px;
  border-radius: 10px;
  background-color: #eeeeea;
	outline: none;
}

.decline-modal-header {
	font-family: Lexend Deca;
	font-size: 18px;
  color: var(--main-color);
	margin-bottom: 18px;
}

.decline-modal-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 76px;
  border-radius: 10px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--main-color);
	border: 0;
	color: #ffffff;
	cursor: pointer;
	outline: none;
}

.pin-modal-cancel-btn {
	margin-top: 16px;
}

.page-lists {
	height: calc(100% - 100px);
	overflow: hidden;
}
